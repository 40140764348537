<template>
  <div class="container">
    <div class="block-view">
      <div class="item">
        <div class="block-item" @click="routerPush('/fund/withdrawal')">
          <img class="bg-image" src="@/assets/images/center/bg-1.png" alt="" />
          <div class="block-box">
            <div class="label">{{ $t("center.extractable") }} FXS</div>
            <div class="value">{{ balance }}</div>
          </div>
        </div>
        <div
          class="block-item"
          @click="routerPush('/fund/recommendationIncome')"
        >
          <img class="bg-image" src="@/assets/images/center/bg-2.png" alt="" />
          <div class="block-box">
            <div class="label">{{ $t("center.recommendedIncome") }}</div>
            <div class="value">{{ reward }}</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="block-item" @click="routerPush('/fund/totalIncomeMcc')">
          <img class="bg-image" src="@/assets/images/center/bg-2.png" alt="" />
          <div class="block-box">
            <div class="label">{{ $t("center.totalRevenue") }} FXS</div>
            <div class="value">{{ totalmcc }}</div>
          </div>
        </div>
        <div class="block-item" @click="routerPush('/fund/totalReleaseMcc')">
          <img class="bg-image" src="@/assets/images/center/bg-1.png" alt="" />
          <div class="block-box">
            <div class="label">{{ $t("center.totalRelease") }} FXS</div>
            <div class="value">{{ shifangmacc }}</div>
          </div>
        </div>
      </div>
      <div class="item center">
        <div class="block-item" @click="routerPush('/fund/totalPledgeMcc')">
          <img class="bg-image" src="@/assets/images/center/bg-2.png" alt="" />
          <div class="block-box">
            <div class="label">{{ $t("center.totalPledge") }}</div>
            <div class="value">{{ zhiyamcc }}</div>
          </div>
        </div>
      </div>
    </div>
    <img class="main-image" src="@/assets/images/center/1.png" alt="" />
    <div class="tip">
      <div class="label">{{ $t("center.myMarketPledgeQuantity") }} FXS</div>
      <div class="value">{{ marketamcc }}</div>
    </div>
    <div class="title">{{ $t("center.invitationLink") }}</div>
    <div
      class="shareLink"
      v-clipboard:copy="share_address"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      <div class="link">{{ share_address }}</div>
      <img src="@/assets/images/copy.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/request/api";
import BigNumber from "bignumber.js";
export default {
  data() {
    return {
      address: "",
      reward: "",
      balance: "",
      zhiyamcc: "",
      marketamcc: "",
      shifangmacc: "",
      totalmcc: "",
    };
  },
  computed: {
    share_address() {
      return `${window.location.origin}/#/home?ref=${this.address}`;
    },
  },
  mounted() {
    this.get_getUserInfo();
  },
  methods: {
    /**
     * 获取用户信息
     */
    get_getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.address = res.data.address;
          this.reward = res.data.reward;
          this.balance = res.data.balance;
          this.zhiyamcc = new BigNumber(Number(res.data.zhiyamcc))
            .plus(Number(res.data.simumcc))
            .toString();
          this.marketamcc = res.data.marketamcc;
          this.shifangmacc = res.data.shifangmacc;
          this.totalmcc = res.data.totalmcc;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    onCopy(e) {
      this.$toast({
        message: this.$t("warning.copySuccess"),
        icon: "certificate",
      });
    },
    onError(e) {
      this.$toast({
        message: this.$t("warning.copyFailed"),
        icon: "fail",
      });
    },
    routerPush(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .block-view {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      display: flex;
      justify-content: space-between;

      &.center {
        justify-content: center;
      }
    }

    .block-item {
      width: calc(50% - 10px);
      position: relative;
      margin-bottom: 20px;

      .bg-image {
        width: 100%;
        display: block;
      }

      .block-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;

        .label {
          font-size: 30px;
          margin-bottom: 20px;
        }

        .value {
          font-size: 40px;
          font-weight: bold;
        }
      }
    }
  }

  .tip {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #b48418;
    padding-bottom: 10px;

    .value {
      font-weight: bold;
      padding-left: 20px;
      font-size: 36px;
    }
  }

  .main-image {
    width: 480px;
    display: block;
    margin: 48px auto 31px;
  }

  .title {
    color: #333;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .shareLink {
    width: 100%;
    // background: linear-gradient(90deg, #77D63C, #68DD75);
    background-color: #b48418;
    box-shadow: 0px 0px 6.133vw 0px rgba(255, 215, 156, 1);
    border-radius: 20px;
    border: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;

    .link {
      font-size: 24px;
      color: #fff;
      flex: 1;
      overflow: hidden;
      word-break: break-all;
    }

    img {
      width: 40px;
      display: block;
      margin-left: 20px;
    }
  }
}
</style>
